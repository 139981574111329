import { last } from 'lodash-es'
import {
  PAY_LINK_USER_STATUS,
  ROUTES,
  DEEPLINK_STEPS,
  BILL_PAYMENT_METHOD_TYPES,
  CARD_SOURCE,
  TASK_FAIL_REASON,
  USER_COMPANY_SELECTION_STATUS
} from '@/util/constants'
import { SUBSCRIPTION_BUNDLE_PROVIDERS } from '@/util/search/pay-link-suggestions'

export function determineInitialPayLinkRoute({ deeplink, userStatus }) {
  const deeplinkToSearch = deeplink?.step === DEEPLINK_STEPS.SEARCH_COMPANY
  const deeplinkToAddCard = deeplink?.step === DEEPLINK_STEPS.ADD_CARD

  if (deeplinkToAddCard) {
    return ROUTES.ADD_CARD
  }

  if (deeplinkToSearch) {
    return ROUTES.PAY_LINK_SEARCH
  }

  const statusRouteMapping = {
    [PAY_LINK_USER_STATUS.NOT_STARTED]: ROUTES.PAY_LINK_WELCOME,
    [PAY_LINK_USER_STATUS.SELECTING]: ROUTES.PAY_LINK_SEARCH,
    [PAY_LINK_USER_STATUS.PRESELECTED]: ROUTES.PAY_LINK_HOME,
    [PAY_LINK_USER_STATUS.IN_PROGRESS]: ROUTES.PAY_LINK_HOME,
    [PAY_LINK_USER_STATUS.FINISHED]: ROUTES.PAY_LINK_HOME
  }

  return statusRouteMapping[userStatus]
}

export function determinePaymentMethodForConnector({
  cards = [],
  accounts = [],
  currentPaymentMethod,
  supportedPaymentMethodTypes,
  connector
}) {
  // TODO: Remove this once credit/debit is supported
  if (isCashAppConnector(connector)) {
    return cards.findLast((card) => !isCredit(card)) || last(accounts)
  }

  if (supportedPaymentMethodTypes.includes(BILL_PAYMENT_METHOD_TYPES.CARD)) {
    return isCard(currentPaymentMethod) ? currentPaymentMethod : last(cards)
  } else {
    return isBank(currentPaymentMethod) ? currentPaymentMethod : last(accounts)
  }
}

export function paymentMethodIsSupported(
  paymentMethod,
  supportedPaymentMethodTypes
) {
  return supportedPaymentMethodTypes.includes(
    getPaymentMethodType(paymentMethod)
  )
}

export function isCashAppConnector(connector) {
  return connector._id === '667455e8f6be1a6459870cb8'
}

export function isCredit(paymentMethod) {
  return paymentMethod.cardType === 'credit'
}

export function getPaymentMethodType(paymentMethod) {
  return (
    paymentMethod &&
    (paymentMethod.expiry
      ? BILL_PAYMENT_METHOD_TYPES.CARD
      : BILL_PAYMENT_METHOD_TYPES.BANK)
  )
}

export function getPaymentMethodAccountType(paymentMethod) {
  return isBank(paymentMethod) ? paymentMethod.type : undefined
}

export function isCard(paymentMethod) {
  return getPaymentMethodType(paymentMethod) === BILL_PAYMENT_METHOD_TYPES.CARD
}

export function isBank(paymentMethod) {
  return getPaymentMethodType(paymentMethod) === BILL_PAYMENT_METHOD_TYPES.BANK
}

export function cardIsValidPaymentMethod(card) {
  return (
    /**
     * If card came from customer, we assume it can be used even if it doesn't
     * have a current token, since that token can be added via data request.
     */
    card.source === CARD_SOURCE.CUSTOMER || !card.isTokenExpired
  )
}

export function hasValidToken(paymentMethod) {
  return Boolean(paymentMethod?.hasToken && !paymentMethod.isTokenExpired)
}

export function missingFullAccountNumber(paymentMethod) {
  return Boolean(
    paymentMethod?.accountNumberLastFour && !paymentMethod.accountNumber
  )
}

export function userNeedsToAddCard({
  supportedPaymentMethodTypes = [],
  cardPaymentMethods = []
}) {
  return (
    supportedPaymentMethodTypes.includes(BILL_PAYMENT_METHOD_TYPES.CARD) &&
    !cardPaymentMethods.length
  )
}

export function removeSpaces(value) {
  return value.replace(/\s/g, '')
}

export function createBinValidator(customer) {
  const binCodes = customer.features?.payLink?.userProvidedCards?.binCodes ?? []

  const isValidationRequired = () => binCodes.length > 0

  return {
    isValidationRequired,
    isValid: (cardNumber) =>
      !isValidationRequired() ||
      binCodes.some((bin) => removeSpaces(cardNumber).startsWith(bin))
  }
}

export function createSelectionAnalyticsPayload(
  selection,
  additionalProperties = {}
) {
  return {
    company: selection.company.name,
    status: selection.status,
    ...additionalProperties
  }
}

/**
 * Temporary workaround to derive a referred company from the task failure
 * message. The backend will provide this information in the future.
 */
export function getManagingCompany({ currentCompany, failureString }) {
  // Special case: Don't return Google if failure string contains "Google Play"
  const googlePlayRegex = new RegExp('Google Play', 'i')
  if (googlePlayRegex.test(failureString)) {
    return undefined
  }

  return Object.values(SUBSCRIPTION_BUNDLE_PROVIDERS).find(
    (company) =>
      company._id !== currentCompany._id &&
      new RegExp(company.name, 'i').test(failureString)
  )
}

/**
 * A Services release is going out soon that will add support for this status
 * on the backend. We'll keep this in Transact for backwards compatibility until
 * all connectors throwing `subscription-managed-by-partner-provider` specify
 * the managing company using the new `managedBy` field.
 */
export function addManagingCompany(selection) {
  if (selection.status === USER_COMPANY_SELECTION_STATUS.FAILED) {
    const task = selection.tasks.find(
      (task) =>
        task.failReason ===
        TASK_FAIL_REASON.SUBSCRIPTION_MANAGED_BY_PARTNER_PROVIDER
    )

    if (task) {
      const managingCompany = getManagingCompany({
        currentCompany: selection.company,
        failureString: [
          task.customFailureTitle,
          task.customFailureMessage
        ].join(' ')
      })

      if (managingCompany) {
        task.managedBy = { company: managingCompany }
        selection.status = USER_COMPANY_SELECTION_STATUS.MANAGING_COMPANY_FOUND
      }
    }
  }

  return selection
}

export function applyOptimisticStatus(optimisticStatuses) {
  return (selection) => {
    if (optimisticStatuses[selection._id]) {
      return {
        ...selection,
        status: optimisticStatuses[selection._id].optimisticStatus
      }
    }
    return selection
  }
}
