<template>
  <div
    v-if="appIsReady"
    class="skeleton-template-wrap"
    :class="{ dark: useDarkMode }"
  >
    <template v-if="isScopePayLink">
      <component :is="payLinkSkeletonComponent" />
    </template>
    <template v-else>
      <TaskHistorySkeleton v-if="isReturnUser" />
      <PreselectedCompanySingleSkeleton
        v-else-if="isPreselectedCompanySingle"
      />
      <PreselectedCompanyMultipleSkeleton
        v-else-if="isPreselectedCompanyMultiple"
      />
      <SearchGridSkeleton v-else-if="isSearchCompany" />
      <WelcomeSkeleton v-else-if="isWelcome" />
      <SearchListSkeleton v-else-if="isSearchPayroll" />
      <ManualFallbackSkeleton v-else-if="isManualFallback" />
      <AdpSkeleton v-else-if="isLogin && isADP" />
      <LoginSkeleton v-else-if="isLogin" />
    </template>
  </div>
</template>

<script>
import WelcomeSkeleton from '@/components/SkeletonLoader/WelcomeSkeleton.vue'
import SearchGridSkeleton from '@/components/SkeletonLoader/SearchGridSkeleton.vue'
import SearchListSkeleton from '@/components/SkeletonLoader/SearchListSkeleton.vue'
import LoginSkeleton from '@/components/SkeletonLoader/LoginSkeleton.vue'
import AdpSkeleton from '@/components/SkeletonLoader/AdpSkeleton.vue'
import TaskHistorySkeleton from '@/components/SkeletonLoader/TaskHistorySkeleton.vue'
import PreselectedCompanySingleSkeleton from '@/components/SkeletonLoader/PreselectedCompanySingleSkeleton.vue'
import PreselectedCompanyMultipleSkeleton from '@/components/SkeletonLoader/PreselectedCompanyMultipleSkeleton.vue'
import PayLinkHomeSkeleton from '@/components/SkeletonLoader/PayLinkHomeSkeleton.vue'
import PayLinkWelcomeSkeleton from '@/components/SkeletonLoader/PayLinkWelcomeSkeleton.vue'
import AddCardSkeleton from '@/components/SkeletonLoader/AddCardSkeleton.vue'
import ManualFallbackSkeleton from '@/components/SkeletonLoader/ManualFallbackSkeleton.vue'
import { mapState, mapGetters } from 'vuex'
import { DEEPLINK_STEPS, PAY_LINK_USER_STATUS, ROUTES } from '@/util/constants'
import { isADPConnector } from '@/util/connector'
import { determineInitialPayLinkRoute } from '@/util/pay-link'

export default {
  name: 'SkeletonWrap',
  components: {
    AddCardSkeleton,
    WelcomeSkeleton,
    SearchGridSkeleton,
    SearchListSkeleton,
    LoginSkeleton,
    AdpSkeleton,
    TaskHistorySkeleton,
    PayLinkHomeSkeleton,
    PayLinkWelcomeSkeleton,
    ManualFallbackSkeleton,
    PreselectedCompanySingleSkeleton,
    PreselectedCompanyMultipleSkeleton
  },
  computed: {
    ...mapState('main', ['appIsReady', 'deeplink']),
    ...mapGetters('main', ['isScopePayLink']),
    ...mapState('user', ['userData']),
    ...mapState('taskHistory', ['taskList']),
    ...mapState('company', ['connector']),
    ...mapGetters('theme', ['useDarkMode']),
    ...mapGetters('taskWorkflow', ['taskWorkflowTypeIsTax']),
    ...mapGetters('user', [
      'incomeSourcesOfTypeChecking',
      'showDetectedIncomeSourcesFlow'
    ]),
    payLinkSkeletonComponent() {
      const initialRoute = determineInitialPayLinkRoute({
        deeplink: this.deeplink,
        userStatus:
          window.atomicStorage.getItem('payLinkUserStatus') ??
          PAY_LINK_USER_STATUS.NOT_STARTED
      })

      const routeSkeletonMap = {
        [ROUTES.PAY_LINK_WELCOME]: PayLinkWelcomeSkeleton,
        [ROUTES.PAY_LINK_SEARCH]: SearchGridSkeleton,
        [ROUTES.PAY_LINK_HOME]: PayLinkHomeSkeleton,
        [ROUTES.ADD_CARD]: AddCardSkeleton
      }

      return routeSkeletonMap[initialRoute]
    },
    deeplinkStep() {
      return this.deeplink?.step
    },
    isWelcome() {
      return !this.deeplinkStep
    },
    isSearchCompany() {
      return this.deeplinkStep === DEEPLINK_STEPS.SEARCH_COMPANY
    },
    isSearchPayroll() {
      return this.deeplinkStep === DEEPLINK_STEPS.SEARCH_PAYROLL
    },
    isLogin() {
      return this.deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY
    },
    isADP() {
      return isADPConnector(this.connector)
    },
    isManualFallback() {
      return this.deeplinkStep === DEEPLINK_STEPS.MANUAL_FALLBACK
    },
    isReturnUser() {
      return this.taskList.length > 0 && !this.taskWorkflowTypeIsTax
    },
    isPreselectedCompanySingle() {
      return (
        this.incomeSourcesOfTypeChecking?.length === 1 &&
        this.showDetectedIncomeSourcesFlow
      )
    },
    isPreselectedCompanyMultiple() {
      return (
        this.incomeSourcesOfTypeChecking?.length > 1 &&
        this.showDetectedIncomeSourcesFlow
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.skeleton-template-wrap {
  background: var(--white);
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: var(--containerBorderRadius);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  z-index: 2;
  will-change: z-index, opacity;
  &.dark {
    background: var(--darkModeBg);
  }
}
</style>
