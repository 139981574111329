import { store } from '@/store'
import {
  DEEPLINK_STEPS,
  FOCUS_DELAYS,
  PAGES,
  ROUTES,
  TASK_WORKFLOW_RESULT,
  TASK_WORKFLOW_VIEW,
  COMPANY_TAGS
} from '@/util/constants'
import router from '@/router'
import Analytics from '@/plugins/analytics'
import { resetTaskState } from '@/util/task'
import {
  authenticationService,
  AUTHENTICATION_EVENTS
} from '@/machines/authentication'
import { unsubscribeTrueAuthAppAnalytics } from '@/util/realtime-helpers/user'
import { prepareKeyboardFocus } from './magic-focus'
import { isADPConnector } from '@/util/connector'

const ROOT_ENTRY_ROUTES = [
  ROUTES.INITIALIZE,
  ROUTES.WELCOME,
  ROUTES.ACCOUNT,
  ROUTES.TASK_HISTORY,
  ROUTES.SEARCH_COMPANY,
  ROUTES.REQUIRE_TERMS,
  ROUTES.PAY_LINK_SEARCH,
  ROUTES.PAY_LINK_WELCOME,
  ROUTES.DEPOSIT_PRESELECTED_COMPANIES
]

/**
 * Computed function that determines when the back arrow should be shown in the app
 */
export function showBackButton() {
  const currentRoute = router.currentRoute.value.name
  const deeplinkStep = store.state.main.deeplink.step
  const authStateWorkaround = store.state.main.authStateWorkaround
  const taskWorkflowStore = store.state.taskWorkflow
  const mainStore = store.state.main
  const formFlowStore = store.state.formFlow
  const hasMultipleAccounts = store.getters['user/userHasMultipleAccounts']
  const isTrueAuthSessionContext =
    store.getters['main/isTrueAuthSessionContext']
  const companyTags = _getCompanyTags({ store })

  let config = {}
  try {
    const rawConfig = window.atomicStorage.getItem('config')
    if (rawConfig) {
      const decodedConfig = atob(rawConfig)
      config = JSON.parse(decodedConfig)
    }
  } catch (error) {
    console.error('Error parsing config:', error)
  }

  if (store.getters['theme/showBackButton'] === false) {
    return false
  }

  // If we are handling MFA or are finished with a Task, hide the back button
  if (
    taskWorkflowStore.taskWorkflowState.view === TASK_WORKFLOW_VIEW.INTERRUPT ||
    taskWorkflowStore.taskWorkflowState.view ===
      TASK_WORKFLOW_VIEW.IN_PROGRESS ||
    (taskWorkflowStore.taskWorkflowState.view === TASK_WORKFLOW_VIEW.FINISHED &&
      taskWorkflowStore.taskWorkflowState.result !==
        TASK_WORKFLOW_RESULT.FAILED)
  ) {
    return false
  }

  if (isTrueAuthSessionContext) {
    return false
  }

  // If user has task history and is on the account page, show the back button
  if (
    currentRoute === ROUTES.ACCOUNT &&
    store.state.taskHistory.taskList.length > 0
  ) {
    return true
  }

  // If user has multiple accounts and is on the search company page, show the back button
  if (currentRoute === ROUTES.SEARCH_COMPANY && hasMultipleAccounts) {
    return true
  }

  // Chime would like to show the back button when deeplinking to company login page
  // Going back will take them back to search
  if (
    deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY &&
    config?.theme?.navigationOptions?.showBackButton &&
    currentRoute === ROUTES.SEARCH_COMPANY
  ) {
    return false
  }

  // If user is deeplinking and the navigationOptions showBackButton is defined, show the back button
  if (
    deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY &&
    config?.theme?.navigationOptions?.showBackButton
  ) {
    return true
  }

  if (
    deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY &&
    companyTags.includes(COMPANY_TAGS.FRANCHISE_PARENT) &&
    currentRoute === ROUTES.SEARCH_FRANCHISE
  ) {
    return false
  }

  // If the user is deeplinking and the navigationOptions showBackButton is NOT defined, hide the back button
  if (
    deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY &&
    currentRoute === ROUTES.TASK &&
    !config?.theme?.navigationOptions?.showBackButton &&
    store.getters['taskWorkflow/rootTaskView'] ===
      taskWorkflowStore.taskWorkflowState.view &&
    authStateWorkaround.value === authStateWorkaround.context.initialState &&
    formFlowStore.currentStepIdx === 0 &&
    !companyTags.includes(COMPANY_TAGS.FRANCHISE_CHILD)
  ) {
    return false
  }

  // If the app is not loaded yet, or the route is base entry point, hide the back button
  if (!currentRoute || ROOT_ENTRY_ROUTES.includes(currentRoute)) {
    return false
  }

  // If the app is deep linked to search payroll companies, hide the back button
  if (
    currentRoute === ROUTES.SEARCH_PAYROLL &&
    deeplinkStep === DEEPLINK_STEPS.SEARCH_PAYROLL &&
    !hasMultipleAccounts
  ) {
    return false
  }

  // If the app is deep linked to the manual fallback screen, hide the back button
  if (deeplinkStep === DEEPLINK_STEPS.MANUAL_FALLBACK) {
    return false
  }

  // If we are deep linked into a company or payroll company and haven't typed in any input, hide the back button
  if (
    currentRoute === ROUTES.TASK &&
    (deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY ||
      deeplinkStep === DEEPLINK_STEPS.LOGIN_PAYROLL) &&
    store.getters['taskWorkflow/rootTaskView'] ===
      taskWorkflowStore.taskWorkflowState.view &&
    authStateWorkaround.value === authStateWorkaround.context.initialState &&
    formFlowStore.currentStepIdx === 0 &&
    !companyTags.includes('franchise-child')
  ) {
    return false
  }

  // If we deeplink and the connector is ADP AND it is hitting the Login Recovery Flow as the initial state, hide the back button
  if (
    (isADPConnector(store.state.company.activeConnector) &&
      deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY) ||
    deeplinkStep === DEEPLINK_STEPS.LOGIN_PAYROLL
  ) {
    return false
  }

  if (
    deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY &&
    authStateWorkaround.value === 'uplinkInterstitial'
  ) {
    return false
  }

  if (
    deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY &&
    currentRoute === ROUTES.TASK &&
    taskWorkflowStore.taskWorkflowState.view === TASK_WORKFLOW_VIEW.MANUAL &&
    !config?.theme?.navigationOptions?.showBackButton
  ) {
    return false
  }

  if (
    currentRoute === ROUTES.PAY_LINK_HOME &&
    mainStore.previousRouteName !== ROUTES.PAY_LINK_SEARCH
  ) {
    return false
  }

  // If the user can process multiple tasks concurrently,
  // we want them to be able to leave an interrupt and come back to it on their own time.
  if (
    store.getters['main/isConcurrentProcessingMode'] &&
    taskWorkflowStore.taskWorkflowState.view === TASK_WORKFLOW_VIEW.INTERRUPT
  ) {
    return true
  }

  // If a linked account is being used, but the connector is under maintenance, hide the back button
  if (
    mainStore.linkedAccountId &&
    (taskWorkflowStore.taskWorkflowState.view ===
      TASK_WORKFLOW_VIEW.INTERSTITIAL.MAINTENANCE_MODE ||
      taskWorkflowStore.taskWorkflowState.view ===
        TASK_WORKFLOW_VIEW.INTERSTITIAL.SYSTEM_AFTER_HOURS)
  ) {
    return false
  }

  return true
}

/**
 * Called when the user presses the back arrow
 * Determines the current state of the app and handles custom back button functionality when appropriate
 */
export async function backButtonHandler({ store }) {
  const currentRoute = router.currentRoute.value.name
  const currentPath = router.currentRoute.value.path
  const taskWorkflowStore = store.state.taskWorkflow
  const analyticsInstance = Analytics.get()
  const companyTags = _getCompanyTags({ store })

  analyticsInstance.track({
    event: 'Clicked Back',
    payload: { fromScreen: currentPath }
  })

  // If the current route is our task process, we have lots of fun back button customizations
  if (currentRoute === ROUTES.TASK) {
    if (
      [
        TASK_WORKFLOW_VIEW.INTERSTITIAL.MAINTENANCE_MODE,
        TASK_WORKFLOW_VIEW.INTERSTITIAL.ROUTING_NUMBER_BLOCKED,
        TASK_WORKFLOW_VIEW.INTERSTITIAL.SYSTEM_AFTER_HOURS,
        TASK_WORKFLOW_VIEW.INTERSTITIAL.PAY_LINK_PRE_LOGIN,
        TASK_WORKFLOW_VIEW.INTERSTITIAL.ADD_CARD
      ].includes(taskWorkflowStore.taskWorkflowState.view)
    ) {
      await store.dispatch('main/goBackToPreviousPage')
    }

    // If the user can process multiple tasks concurrently,
    // we want them to be able to leave an interrupt and come back to it on their own time.
    else if (
      store.getters['main/isConcurrentProcessingMode'] &&
      taskWorkflowStore.taskWorkflowState.view === TASK_WORKFLOW_VIEW.INTERRUPT
    ) {
      await store.dispatch('main/goBackToPreviousPage')
    } else if (
      taskWorkflowStore.taskWorkflowState.view === TASK_WORKFLOW_VIEW.LOGIN &&
      companyTags.includes('franchise-child')
    ) {
      await store.dispatch('main/goBackToPreviousPage')
    }

    // If the user is currently filling out a form
    else if (
      taskWorkflowStore.taskWorkflowState.view === TASK_WORKFLOW_VIEW.LOGIN
    ) {
      unsubscribeTrueAuthAppAnalytics({ store })

      authenticationService.send(AUTHENTICATION_EVENTS.BACK)
    }
    // If the user is having to change their deposit settings because of fractional error and they click back,
    // then just go back to the previous page using the main router
    else if (
      taskWorkflowStore.taskWorkflowState.view ===
      TASK_WORKFLOW_VIEW.FRACTIONAL_DEPOSIT_ERROR
    ) {
      await store.dispatch('main/goBackToPreviousPage')
    }
    // If the user failed their task, go back to the beginning of form flow
    else if (
      taskWorkflowStore.taskWorkflowState.view ===
        TASK_WORKFLOW_VIEW.FINISHED &&
      taskWorkflowStore.taskWorkflowState.result ===
        TASK_WORKFLOW_RESULT.FAILED &&
      // For concurrent processing mode, send the user back home
      !store.getters['main/isConcurrentProcessingMode']
    ) {
      return await resetTaskState({
        initialView: TASK_WORKFLOW_VIEW.LOGIN,
        analyticEvent: {
          event: `Clicked Back From ${PAGES.AUTHENTICATION_FAILED}`
        }
      })
    } else if (
      taskWorkflowStore.taskWorkflowState.view ===
        TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION &&
      !store.getters['main/allowManualFallbackMethods'] &&
      store.getters['userDeviceAutomation/usingUserAutomatedDevice']
    ) {
      authenticationService.send(AUTHENTICATION_EVENTS.BACK)
    } else if (
      taskWorkflowStore.taskWorkflowState.view ===
        TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION &&
      store.state.distribution.fractionalError.requiresAction
    ) {
      return await resetTaskState({
        initialView: TASK_WORKFLOW_VIEW.LOGIN,
        analyticEvent: {
          event: `Clicked Back From ${PAGES.CONFIRM_DISTRIBUTION}`
        }
      })
    } else if (
      taskWorkflowStore.taskWorkflowState.view ===
      TASK_WORKFLOW_VIEW.INTERSTITIAL.MANUAL_FALLBACK
    ) {
      await store.dispatch('main/goBackToPreviousPage')
    } else if (
      [
        TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED,
        TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_ACCOUNT_ROUTING,
        TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_SUMMARY,
        TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED_SUCCESS
      ].includes(taskWorkflowStore.taskWorkflowState.view) ||
      (taskWorkflowStore.taskWorkflowState.view ===
        TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION &&
        store.getters['main/allowManualFallbackMethods'])
    ) {
      await store.dispatch('taskWorkflow/goBackInHistory')
    } else {
      if (store.getters['userDeviceAutomation/usingUserAutomatedDevice']) {
        authenticationService.send(AUTHENTICATION_EVENTS.BACK)
      } else if (store.getters['taskWorkflow/canGoBackInHistory']) {
        await store.dispatch('taskWorkflow/goBackInHistory')
      } else if (
        // If showbackbutton is true and user has deeplinked to a company login page, go back to search
        store.getters['theme/showBackButton'] &&
        TASK_WORKFLOW_VIEW.MANUAL
      ) {
        router.push({ name: ROUTES.SEARCH_COMPANY })
      } else {
        await store.dispatch('main/goBackToPreviousPage')
      }
    }
  }
  // Otherwise just handle typical page navigation using the main router
  else {
    await store.dispatch('main/goBackToPreviousPage')
  }

  prepareKeyboardFocus()
  await _handleTriggerElementFocusOnBackwardNavigation()
}

async function _handleTriggerElementFocusOnBackwardNavigation() {
  const allPreviouslyFocusedElementIds =
    store.state.activeElement.activeElementHistory

  await store.dispatch('activeElement/updateIsNavigatingBackward', true)

  setTimeout(async () => {
    const triggerElementFoundAndFocused = await attemptFocusToTriggerElements(
      allPreviouslyFocusedElementIds
    )

    if (!triggerElementFoundAndFocused) {
      document.getElementById('focusHeading')?.focus()
    }

    if (onInitialPage() && allPreviouslyFocusedElementIds.length >= 1) {
      await store.dispatch('activeElement/updateElementHistory', 0)
    }

    await store.dispatch('activeElement/updateIsNavigatingBackward', false)
  }, FOCUS_DELAYS.NAV_BACK_DELAY + 200) // giving extra time to ensure animations are complete
}

async function attemptFocusToTriggerElements(allFocusedElementIds) {
  for (let i = allFocusedElementIds.length - 1; i >= 0; i--) {
    const element = document.getElementById(allFocusedElementIds[i])

    // The horizontal scrolling when focusing on a carousel item shifts the entire page, so
    // for now we're going to work around that by not focusing carousel items
    if (element && !allFocusedElementIds[i].startsWith('carouselButton')) {
      element.focus()
      await store.dispatch('activeElement/updateElementHistory', i)

      return true
    }
  }

  return false
}

function onInitialPage() {
  const deeplinkStep = store.state.main.deeplink.step
  const currentRouteName = router.currentRoute.value.name

  return deeplinkStep
    ? kebabCaseToPascalCase(deeplinkStep) === currentRouteName
    : [ROUTES.SEARCH_COMPANY, ROUTES.PAY_LINK_SEARCH].includes(currentRouteName)
}

function kebabCaseToPascalCase(kebabCase) {
  const clearAndUpper = (text) => {
    return text.replace(/-/, '').toUpperCase()
  }

  return kebabCase.replace(/(^\w|-\w)/g, clearAndUpper)
}

function _getCompanyTags({ store }) {
  const companyTags = store.state.company.tags || []
  const franchiseTags = store.state.company.franchiseParent?.tags || []

  return companyTags.length ? companyTags : franchiseTags
}
