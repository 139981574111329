import {
  TASK_WORKFLOW_VIEW,
  PAGES,
  ROUTES,
  SDK_EVENT_TYPES,
  TASK_WORKFLOW_TYPES,
  MODAL_VIEW,
  PRODUCTS
} from '@/util/constants'
import { triggerAnalyticEvent } from '@/util/analytics'
import { appClose } from '@/util/app-event'
import Analytics from '@/plugins/analytics'

export function buildExitContent({ store, router }) {
  if (
    store.getters['taskWorkflow/productsIncludeSwitch'] &&
    store.getters['payLink/inProgressSelections'].length > 0
  ) {
    return _switchInProgressExitContent({ store, router })
  }

  switch (router?.currentRoute.value.name) {
    case ROUTES.SEARCH_COMPANY:
    case ROUTES.SEARCH_PAYROLL:
      return _searchExitContent({ store, router })
    case ROUTES.TASK:
      return _taskExitContent({ store, router })
    default:
      return _genericExitContent({ store, router })
  }
}

// TODO: Translations
function _switchInProgressExitContent({ store, router }) {
  const theme = { customerBranded: true }
  const inProgressSelections = store.getters['payLink/inProgressSelections']

  return {
    title: `You have ${inProgressSelections.length} pending update${
      inProgressSelections.length > 1 ? 's' : ''
    }`,
    description:
      'We just need ~30 seconds to finish. If you exit now, your updates will be canceled, and you will need to re-do them.',
    actionItems: [
      _waitAndCompleteUpdatesCTA({ store, router, theme }),
      _exitAndCancelUpdatesCTA({ store, router })
    ]
  }
}

// TODO: Translations
function _waitAndCompleteUpdatesCTA({ store }) {
  return {
    text: 'Wait and complete updates',
    method: () => {
      store.dispatch('modal/closeModal')
    }
  }
}

// TODO: Translations
function _exitAndCancelUpdatesCTA({ store, router }) {
  return {
    text: 'Exit and cancel updates',
    method: () => {
      Analytics.get().track({
        event: `Closed With Updates In Progress`
      })
      _triggerAppClose({ store, router })
    }
  }
}

function _searchExitContent({ store, router }) {
  const theme = { customerBranded: true }

  return {
    title: store.getters['i18n/phrases'].exitModal.search.title,
    description: store.getters['i18n/phrases'].exitModal.search.description,
    helperAction: {
      text: store.getters['i18n/phrases'].exitModal.search.helperAction,
      method: () => {
        _triggerChangeToFindEmployerHelpModal({ store })
      }
    },
    actionItems: [
      ..._returnToFiCTA({ store, router, theme }),
      ..._manualDepositFlowCTA({ store, router })
    ]
  }
}

function _taskExitContent({ store, router }) {
  const theme = { taskBranded: true }

  switch (store.state.taskWorkflow.taskWorkflowState.view) {
    case TASK_WORKFLOW_VIEW.LOGIN:
      return _authenticationExitContent({ store, router })
    case TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION:
      return _confirmDistributionExitContent({ store, router })
    default:
      return _genericExitContent({ store, router, theme })
  }
}

function _authenticationExitContent({ store, router }) {
  const theme = { taskBranded: true }

  return {
    title: store.getters['i18n/phrases'].exitModal.login.title,
    description: store.getters['i18n/phrases'].exitModal.login.description,
    helperAction: {
      text: store.getters['i18n/phrases'].exitModal.login.helperAction,
      method: () => {
        _triggerChangeToFindCredentialsHelpModal({ store })
      }
    },
    actionItems: [
      ..._returnToFiCTA({ store, router, theme }),
      ..._manualDepositFlowCTA({ store, router })
    ]
  }
}

function _confirmDistributionExitContent({ store, router }) {
  const theme = { taskBranded: true }
  const canChange = store.getters['distribution/canChangeDistribution']
  return {
    title: canChange
      ? store.getters['i18n/phrases'].exitModal.distribution.canEditTitle
      : store.getters['i18n/phrases'].exitModal.distribution.title,
    description: canChange
      ? store.getters['i18n/phrases'].exitModal.distribution.description
          .canChange
      : store.getters['i18n/phrases'].exitModal.distribution.description
          .noChange,
    actionItems: [
      ..._returnToFiCTA({ store, router, theme }),
      ..._changeDepositCTA({ store }),
      ..._manualDepositFlowCTA({ store, router })
    ]
  }
}

function _genericExitContent({ store, router }) {
  const theme = { customerBranded: true }
  const genericContent = _determineGenericExitContent({ store })
  return {
    title: genericContent.title,
    description: genericContent.description,
    actionItems: [
      ..._returnToFiCTA({ store, router, theme }),
      ..._manualDepositFlowCTA({ store, router })
    ]
  }
}

function _triggerAppClose({ store, router }) {
  triggerAnalyticEvent({
    eventName: `Clicked Yes, Exit From ${PAGES.EXIT_CONFIRMATION}`
  })
  appClose({
    store,
    route: router.currentRoute.value.name,
    eventType: SDK_EVENT_TYPES.CLOSE
  })
}

async function _triggerRouteToManualMethods({ store, router }) {
  triggerAnalyticEvent({
    eventName: `Clicked Set Up Manually From ${PAGES.EXIT_CONFIRMATION}`
  })
  await store.dispatch(
    'taskWorkflow/updateTaskWorkflowType',
    TASK_WORKFLOW_TYPES.MANUAL_FALLBACK
  )
  await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.INTERSTITIAL.MANUAL_FALLBACK
  })
  await store.dispatch('modal/closeModal')
  await router.push({ name: ROUTES.TASK })
}

async function _triggerChangeToDepositEditModal({ store }) {
  triggerAnalyticEvent({
    eventName: `Clicked Change From ${PAGES.EXIT_CONFIRMATION}`
  })

  await store.dispatch('modal/closeModal')

  const distributionTypes =
    store.state.taskWorkflow.taskWorkflowState?.data?.distributionTypes ||
    store.state.company.connector.capabilities?.distributionTypes ||
    []

  await store.dispatch('distribution/clearDistributionState')
  await store.dispatch('modal/openModal', {
    view: MODAL_VIEW.DISTRIBUTION_OPTIONS,
    overlay: true,
    data: { availableDistributionTypes: distributionTypes }
  })
}

async function _triggerChangeToFindEmployerHelpModal({ store }) {
  triggerAnalyticEvent({
    eventName: `Clicked How To Find Your Payroll Employer`
  })
  await store.dispatch('modal/closeModal')
  await store.dispatch('modal/openModal', {
    view: MODAL_VIEW.FIND_EMPLOYER_HELP,
    overlay: true
  })
}

async function _triggerChangeToFindCredentialsHelpModal({ store }) {
  triggerAnalyticEvent({
    eventName: `Clicked How To Find Your Credentials`
  })
  await store.dispatch('modal/closeModal')
  await store.dispatch('modal/openModal', {
    view: MODAL_VIEW.FIND_CREDENTIALS_HELP,
    overlay: true
  })
}

function _manualDepositFlowCTA({ store, router }) {
  return store.getters['main/showManualFallbackMethods']
    ? [
        {
          text: store.getters['i18n/phrases'].exitModal.actionItems
            .setUpManually,
          method: () => {
            _triggerRouteToManualMethods({ store, router })
          }
        }
      ]
    : []
}

function _returnToFiCTA({ store, router, theme }) {
  return store.state.main.inSdk
    ? [
        {
          text: store.getters['i18n/phrases'].shared.returnToCustomer({
            customerName: store.getters['user/customerName']
          }),
          method: () => {
            _triggerAppClose({ store, router })
          },
          customerBranded: theme.customerBranded,
          taskBranded: theme.taskBranded
        }
      ]
    : []
}

function _changeDepositCTA({ store }) {
  return store.getters['distribution/canChangeDistribution']
    ? [
        {
          text: store.getters['i18n/phrases'].exitModal.actionItems
            .changeDepositAmount,
          method: () => {
            _triggerChangeToDepositEditModal({ store })
          }
        }
      ]
    : []
}

function _determineGenericExitContent({ store }) {
  const firstProduct = store.getters['taskWorkflow/firstProduct']

  if (store.getters['taskWorkflow/taskWorkflowTypeIsTax']) {
    return {
      title: store.getters['i18n/phrases'].exitModal.generic.tax.title,
      description:
        store.getters['i18n/phrases'].exitModal.generic.tax.description
    }
  } else if (firstProduct === PRODUCTS.DEPOSIT) {
    return {
      title: store.getters['i18n/phrases'].exitModal.generic.deposit.title,
      description:
        store.getters['i18n/phrases'].exitModal.generic.deposit.description
    }
  } else if (firstProduct === PRODUCTS.VERIFY) {
    return {
      title: store.getters['i18n/phrases'].exitModal.generic.verify.title,
      description:
        store.getters['i18n/phrases'].exitModal.generic.verify.description
    }
  } else if (firstProduct === PRODUCTS.SWITCH) {
    return {
      title: store.getters['i18n/phrases'].exitModal.generic.switch.title,
      description:
        store.getters['i18n/phrases'].exitModal.generic.switch.description
    }
  } else {
    return {
      title: store.getters['i18n/phrases'].exitModal.generic.deposit.title,
      description:
        store.getters['i18n/phrases'].exitModal.generic.deposit.description
    }
  }
}
